import APIUrl from "../../APIUrl";
import axios from "axios";

export const addToken = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.addToken, data)
      .then(function (response) {
        if (successCallback) successCallback(response);
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const resetPassword = function (token, data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.resetPassword + token, data)
      .then(function (response) {
        return successCallback(response);
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const checkToken = function (data, existsCallback, noExistsCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.checkToken, data)
      .then(function (response) {
        if (response && response.status === 200) {
          if (existsCallback) existsCallback();
        } else {
          if (noExistsCallback) noExistsCallback();
        }
      })
      .catch(function (err) {
        throw err;
      });
  };
};
