import React from "react";
import { connect } from "react-redux";
import Util from "../../util/Util";
import {
  checkToken,
  resetPassword,
} from "../../actions/passwordRecoveryTokens/passwordRecoveryTokens";
import { FormattedMessage, injectIntl } from "react-intl";
import Notification from "../sub/Notification";
import { Button, Modal } from "react-bootstrap";
class NewPwdForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      password: "",
      passwordConfirmation: "",
      disabled: false,
      spinner: "fa fa-spinner fa-spin d-none",
      btnMessage: <FormattedMessage id="Reset" />,
      modal: null,
    };
  }

  closeModal() {
    this.setState({ modal: null });
  }

  redirectToLogin() {
    window.location.replace(Util.checkHttps(process.env.REACT_APP_PHARMA_URL));
  }

  componentDidMount() {
    this.props.onCheckToken(
      { token: this.props.match.params.token },
      () => this.setState({ modal: null }),
      () =>
        this.setState({
          modal: (
            <Modal
              show={true}
              backdrop="static"
              keyboard={false}
              onHide={() => this.closeModal()}
              size="md"
              centered
            >
              <Modal.Header className={"bg-danger"}>
                <Modal.Title className="text-white">
                  <FormattedMessage id="Unexpected.Error" />
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <FormattedMessage id="Token.Invalid" />
              </Modal.Body>
              <Modal.Footer>
                <Button variant="danger" onClick={() => this.redirectToLogin()}>
                  <FormattedMessage id="Ok" />
                </Button>
              </Modal.Footer>
            </Modal>
          ),
        }),
    );
  }

  handleSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    if (
      !this.props.match.params.token ||
      this.state.disabled ||
      this.hasEmptyField()
    )
      return;

    // Check password parity
    if (this.state.password !== this.state.passwordConfirmation) {
      Notification({
        type: "error",
        description: this.props.intl.formatMessage({
          id: "Password.Does.Not.Match",
        }),
      });
      return;
    }

    // Check password syntax
    if (!this.checkValidPasswordForRecovery(this.state.password)) {
      Notification({
        type: "error",
        description: this.props.intl.formatMessage({
          id: "Password.Not.Conform",
        }),
      });
      return;
    }

    this.setState({ disabled: true });
    this.setState({ spinner: "fa fa-spinner fa-spin" });
    this.setState({ btnMessage: <FormattedMessage id="Plz.Wait" /> });

    let that = this;
    function onResetPasswordSuccess() {
      that.props.history.push("/reset/success");
    }

    var token = this.props.match.params.token;
    var url = this.props.match.params.company;

    var data = {
      password: this.state.password,
      url: url,
    };

    this.props.onResetPassword(token, data, onResetPasswordSuccess);
  }

  // New method to validate full username during recovery process
  // https://www.thepolyglotdeveloper.com/2015/05/use-regex-to-test-password-strength-in-javascript/
  checkValidPasswordForRecovery(password) {
    var pattern = new RegExp(
      "^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})",
    );
    return pattern.test(password);
  }

  hasEmptyField() {
    // Check password parity
    if (this.state.password !== this.state.passwordConfirmation) {
      return true;
    }

    return !Util.emptyString(this.state.password) &&
      !Util.emptyString(this.state.passwordConfirmation)
      ? false
      : true;
  }

  onFieldChange(field, value) {
    this.setState({ [field]: value });
  }

  render() {
    return (
      <div className="vertical-center no-bg p-0 m-0 text-center">
        <div className="container pl-5 pr-5 pb-0 mw-600">
          <img
            className="logo-login"
            alt=""
            height={"250em"}
            src="./images/svg/pharmamad_logo.svg"
          />

          <form className="pt-5" onSubmit={(e) => this.handleSubmit(e)}>
            <div className="form-group row">
              <label htmlFor="password">
                <FormattedMessage id="Reset.Password" />
              </label>{" "}
              :
              <div className="input-group">
                <div className="input-group-prepend">
                  <div className="input-group-text bg-light">
                    <i className="fa fa-key fa-fw"></i>
                  </div>
                </div>
                <input
                  type="password"
                  id="password"
                  name="password"
                  className="form-control"
                  value={this.state.password}
                  onChange={(e) =>
                    this.onFieldChange("password", e.target.value.trim())
                  }
                />
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="password">
                <FormattedMessage id="Confirm.Password" />
              </label>{" "}
              :
              <div className="input-group">
                <div className="input-group-prepend">
                  <div className="input-group-text bg-light">
                    <i className="fa fa-key fa-fw"></i>
                  </div>
                </div>
                <input
                  type="password"
                  id="passwordConfirmation"
                  name="passwordConfirmation"
                  className="form-control"
                  value={this.state.passwordConfirmation}
                  onChange={(e) =>
                    this.onFieldChange(
                      "passwordConfirmation",
                      e.target.value.trim(),
                    )
                  }
                />
              </div>
            </div>

            <button
              type="submit"
              className="btn btn-info"
              disabled={this.state.disabled || this.hasEmptyField()}
            >
              <i id="spinner" className={this.state.spinner}></i>{" "}
              {this.state.btnMessage}
            </button>
          </form>
          {this.state.modal}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    //
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onResetPassword: (token, data, successCallback, failureCallback) =>
      dispatch(resetPassword(token, data, successCallback, failureCallback)),
    onCheckToken: (data, existsCallback, noExistsCallback) =>
      dispatch(checkToken(data, existsCallback, noExistsCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(NewPwdForm));
